import axios from 'axios'

export default class UserInfo {
  constructor () {
    this._attributes = JSON.parse(localStorage.getItem('userInfo'))
    this._isLoad = false
  }

  get id () {
    return this._attributes.id
  }

  get code () {
    return this._attributes.code
  }

  get author_id () {
    return this._attributes.author_id
  }

  set author_id (author_id) {
    this._attributes.author_id = author_id
  }

  get authority () {
    return this._attributes.authority
  }

  get expired_time () {
    return this._attributes.expired_time
  }

  get user_name () {
    return this._attributes.user_name
  }

  get is_locked () {
    return this._attributes.is_locked
  }

  get unlock_time () {
    return this._attributes.unlock_time
  }

  isVip () {
    return this.id && this.authority > 0
  }

  isCampusVip () {
    return this.authority > 1
  }

  async fetchAuthor () {
    return axios.get(`/resourceAdmin/authors/${this.id}/user`).then(({ data }) => {
      this._attributes.author_id = data.id
      this._isLoad = true
      saveInfomation(this._attributes)
    }).catch((e) => {
      if (e.response.status === 404) {
        this._isLoad = true
      }
    })
  }

  __update__ (attributes) {
    for (const k in attributes) {
      this._attributes[k] = attributes[k]
    }
  }

  async fetch () {
    return axios.get(`/userAdmin/users/${this.id}?code=${this.code}`).then(({ data }) => {
      this.__update__(data)
    })
  }
}

function saveInfomation (item) {
  const userInfo = {
    id: item.id,
    author_id: item.author_id,
    code: item.code,
    user_name: item.user_name,
    authority: item.authority,
    expired_time: item.expired_time
  }
  localStorage && localStorage.setItem('userInfo', JSON.stringify(userInfo))
}
