<template>
 <div id="app">
  <keep-alive >
    <router-view/>
  </keep-alive>
 </div>
</template>

<script>
export default {
  props: {
  },
  components: {},
  data () {
    return {
    }
  },
  mounted() {
    this.$nextTick(_ => {
      let realHeight = window.innerHeight
      this.setMetaHeight(realHeight)
    })
  },
methods: {
 // 设置meta高度 解决安卓浏览器软键盘弹起，占用窗口的面积问题
 setMetaHeight(height) {
	document.head.querySelector("meta[name='viewport']").setAttribute('id', 'viewportMeta')
	let metaEl = document.querySelector('#viewportMeta')
	let content = 'height=' + height + ',width=device-width,initial-scale=1.0,user-scalable=no'
	metaEl.setAttribute('name', 'viewport')
	metaEl.setAttribute('content', content)
	this.$forceUpdate()
 }
}

}
</script>

<style lang="less">

* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}
html,body,#app{
  width: 100%;
  height: 100%;
}
.empty {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #969696;
  font-size: 20px;
}

/**去除点击按钮 阴影**/
a,button,input {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	outline: 0;
}
.npsitefont{
  font-size: 12px;
}
.van-pull-refresh{
  height: 220px;
  overflow: visible !important;  
}
::-webkit-scrollbar {
            display: none;
    }

</style>
