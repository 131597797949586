import Vue from 'vue'
import VueRouter from 'vue-router'


const Login = () =>
    import ('@/views/Login/index.vue')
const Home = () =>
    import ('@/views/Home')
const My = () =>
    import ('@/views/my/index.vue')
const MyResources = () =>
    import ('@/components/resources/myResources.vue')
const MyResourcesPacks = () =>
    import ('@/components/resourcesPacks/myResourcesPacks.vue')
const AuthorizationResourcePacks = () =>
    import ('@/views/authorizationResourcePacks/index.vue')
const CollectionResource = () =>
    import ('@/views/collectionResource/index.vue')
const CollectionResources = () =>
    import ('@/components/resources/collectionResource.vue')
const CollectionResourcesPacks = () =>
    import ('@/components/resourcesPacks/collectionResourcesPacks.vue')
const LatestAccess = () =>
    import ('@/views/latestAccess')
const ResourcesPlayer = () =>
    import ('@/views/resourcesPlayer')
const ResourcesPacks = () =>
    import ('@/views/resourcesPacks')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { rank: 1 }
  },
  {
    path: '/my',
    component: My,
    redirect: '/my/Resource',
    children: [
      {
        path: 'Resource',
        name: 'myResources',
        component: MyResources,
        meta: { rank: 2 }
      },
      {
        path: 'Resourcepacks',
        name: 'myResourcesPacks',
        component: MyResourcesPacks,
        meta: { rank: 2 }
      }
    ],
    meta: { rank: 1.5 }
  },
  {
    path: '/authorizationResourcePacks',
    component: AuthorizationResourcePacks,
    meta: { rank: 1.5 }
  },
  {
    path: '/collection',
    component: CollectionResource,
    redirect: '/collection/Resources',
    children: [
      {
        path: 'Resources',
        component: CollectionResources,
        meta: { rank: 2 }
      },
      {
        path: 'ResourcePacks',
        component: CollectionResourcesPacks,
        meta: { rank: 2 }
      }
    ],
    meta: { rank: 1.5 }
  },
  {
    path: '/latestAccess',
    component: LatestAccess,
    meta: { rank: 2 }
  },
  {
    path: '/resourcesPlayer/:course/:id',
    name: 'Resourcesplayer',
    component: ResourcesPlayer,
    meta: { rank: 3 }
  },
  {
    path: '/resourcesPacks/:id',
    name: 'resourcesPacks',
    component: ResourcesPacks,
    meta: { rank: 2.5 }
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫,控制页面访问权限

router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
  // 获取token
  const tokenStr = window.localStorage.getItem('userInfo')
  if (!tokenStr) return window.location.href = `${process.env.VUE_APP_BASE_HOST}/oauthui/#/login/?isredirect=true&redirect_uri=${document.location.origin}/%23/login`
}
  next()
})

export default router
