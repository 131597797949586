import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import {Tree} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import UserInfo from '@/components/Domian/UserInfo'
import './assets/iconfont/font/iconfont.css'
import 'amfe-flexible'
import { NavBar ,Search ,PullRefresh ,Lazyload ,Loading } from 'vant'
import '@vant/touch-emulator';
import mui from 'vue-awesome-mui';

Vue.config.productionTip = false
Vue.use(NavBar).use(Search).use(PullRefresh).use(Lazyload, {lazyComponent: true,}).use(Loading)
Vue.use(Tree);
Vue.use(mui);

const user = new UserInfo()
Vue.prototype.$user = user
Vue.prototype.$axios = axios

axios.interceptors.response.use(function(response){
    return response
},async function(err){
if (err.response.status === 401) {
    localStorage.removeItem('userInfo')
    window.location.href = `${process.env.VUE_APP_BASE_HOST}/oauthui/#/login/?isredirect=true&redirect_uri=${document.location.origin}/%23/login`
}
return Promise.reject(err)
})

Vue.mixin({
    beforeRouteLeave(to, from, next) {
      const toRank = to.meta.rank;
      const fromRank = from.meta.rank;
  
      // 如果当前路由的 rank 比上一个路由的 rank 小，即返回上一层，则销毁缓存
      if (toRank !== undefined && fromRank !== undefined && fromRank > toRank) {
        const vnode = this.$vnode;
        if (vnode && vnode.data.keepAlive) {
          const cache = vnode.parent.componentInstance.cache;
          const keys = vnode.parent.componentInstance.keys;
          const key = vnode.key == null ? vnode.componentOptions.Ctor.cid + (vnode.componentOptions.tag ? `::${vnode.componentOptions.tag}` : '') : vnode.key;
  
          if (cache[key]) {
            if (keys.length) {
              const index = keys.indexOf(key);
              if (index > -1) {
                keys.splice(index, 1);
              }
            }
            delete cache[key];
          }
        }
        this.$destroy();
      }
      next();
    },
  });

function gotoURL(callback){
    window.onpopstate = null;
    window.onpopstate = function(){
        callback()
    }
}
Vue.prototype.$gotoURL = gotoURL
addCNZZ()
function addCNZZ() {
    let cnzz = document.createElement('script');
    cnzz.src = " https://s11.cnzz.com/z_stat.php?id=1257414806&web_id=1257414806";
    document.getElementsByTagName('body')[0].appendChild(cnzz);
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
